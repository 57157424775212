import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UPBsModalConfig, UPBsModalEvent, ModalSubmissionType } from 'up';
import { SharedService } from 'src/app/shared/services/shared.service';
@Component({
  selector: 'go-interceptor-error',
  templateUrl: './interceptor-error.component.html',
  styleUrls: ['./interceptor-error.component.scss'],
})
export class InterceptorErrorComponent implements OnInit {
  bsModalConfig: UPBsModalConfig = {
    title: '',
    submitType: ModalSubmissionType.CLOSE_MODAL,
    submitBtnText: 'LEARNER.WELCOME_MODAL.OK_BUTTON_TEXT',
    closeBtnText: '',
    hideCloseBtn: true,
    hideFooter: false,
    hideCloseIcon: true,
    featureType: '',
  };
  @Input() modalTitle!: string;
  @Input() modalBody!: string;
  constructor(
    private modalService: NgbModal,
    private sharedService: SharedService,
  ) {}

  ngOnInit(): void {
    this.bsModalConfig.title = this.modalTitle;
    this.bsModalConfig.featureType = 'authentication-error';
  }

  handleModalEvent(event: UPBsModalEvent) {
    switch (event.type) {
      case ModalSubmissionType.CLOSE_MODAL:
        this.sharedService.onExit();
        break;
    }
    this.modalService.dismissAll();
  }
}
